<template>
    <div class="ktiphone_container">
        <kt-iphone />
    </div>
</template>
<script>
export default {
  name: 'KTIphone',
  components: {
    'kt-iphone': () => import(`@/components/eventView/ktiphone/KtIphone.vue`)
  },
  data () {
    return {};
  },

  mounted () {},

  methods: {},

  beforeMount () {}
};
</script>
<style>
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
</style>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped>
.ktiphone_container {
  width: 100%;
  height: 100%;
  background-color: var(--000000);
  /* display: flex;
  justify-content: center; */
}
</style>
